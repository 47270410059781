/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "@components/Layouts"
import SEO from "@components/seo"
import ArticlePost from "@components/ArticlePost"
import Section from "@components/Section"
export default function Template ({
  data, // this prop will be injected by the GraphQL query below.
  ...props
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { title, ogTitle, ogdescription, ogimage } = frontmatter

  return (
    <Layout>
      <SEO
        title={ogTitle || title}
        pathname={props.location.pathname}
        image={ogimage || frontmatter.image}
        description={ogdescription || frontmatter.cardPreview}
      />
      <Section
        className="pink-linear"
        sx={{
          bg: "pink",
          color: "darkPurple",
        }}
      >
        <ArticlePost {...frontmatter} html={html} path={props.path} isNews />
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image
        cardPreview
        ogTitle
        ogdescription
        ogimage
      }
    }
  }
`
